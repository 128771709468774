<template>
    <div class="companyReview">
        <headLine msg='双选会审核'></headLine>
        <div class="table">  
            <commonTable :tableData='tableData' :dictionary='dictionary'
            @btnFun='btnFun'></commonTable> 
        </div>
       <paging v-show="tableData.length>0" :totalpage="totalpage" @pageinfo="getInitList" ref="pages" v-on:handle="handle"></paging>
    </div>
</template>
<script>
export default { 
    data() {
        return {
            tableData:[],
            current:0,
            totalpage:0,
            dictionary:[
                {headname:"状态",key:"tag",tagType:'state'},
                {headname:"双选会时间",key:"date",width:'180px'},
                {headname:"双选会名称",key:"dcName",width:'200px'},
                {headname:"申请企业数量",key:"companyNum",},
                {headname:"审核通过数量",key:"passNum",},
                {headname:"岗位数量",key:"jobNum",},
                {headname:"发布时间",key:"createdAt"},
                {headname:"发布人",key:"createName"},
                {headname:"审核",key:"button"},
            ]     
        }
    },
    
    methods: {
        btnFun(row){
             this.$router.push({path:"/review",query:{id:row.id}})
        },
        getInitList(){
            this.$htp.get_('/tea/dc/public/list?page='+this.current+'&teaId='+this.teaId).then((res)=>{
                this.tableData=[]
                res.data.rows.forEach(e=>{
                    let v={}
                    let {state,dcName,dcDetail,createdAt,createName,id}=e
                    const date=`${e.startTime} - ${e.endTime}`
                    const {companyNum,passNum,jobNum}=e.dcDetail
                    v={tag:state,dcName,dcDetail,createdAt,createName,date,companyNum,passNum,jobNum,id}
                    v.button={
                        type:'button',
                        text:'企业审核',
                        color:'danger',
                        size:'mini'
                    }
                    this.tableData.push(v)
                })
                this.totalpage=res.data.total
            }) 
        },
        handle(val){
            this.current=val
        }
    },
    mounted(){
        this.teaId=sessionStorage.getItem('id')
        this.getInitList()
    }
}
</script>
<style lang="scss" scoped>
@import '../../assets/css/doubleElection.scss';

</style>
<style>
.companyReview .el-table thead,.doubleElection .el-table th{
    background-color: #f2f6fc !important;
}
</style>